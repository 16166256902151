.hover-button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.hover-button:hover {
  background-color: darkblue;
}

.productName {
  margin-top: 15px;
  font-weight: bold;
  font-size: 24px
}


.productBorderItem {
  border: 3px solid #f7f7f7;
  border-radius: 7px;
  width: 300px;
  margin: 5px;
  text-align: center;
  background-color: antiquewhite;
  padding: 10px 10px;
}

.productBorderItem:hover {
  background-color: rgb(221, 255, 228);
}